import {
  ModuleCategory,
  SchemaTypeHelper,
  SingleUnion,
  StringEnum,
  SubscribesTo,
  backgroundSchema,
  createComponentAdmin,
  layoutSchema,
  layoutSchemaAdvanced,
  opacitySchema,
  scrollToDescription,
  sizingSchema,
  spacingSchema,
} from '@backstage-components/base';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'Stacked';
export const name = reactName;
export const description = reactName;
const category: ModuleCategory = 'layout';

/**
 * The 6 possible orientations for a position
 */
export const positions = [
  '',
  'unset',
  'static',
  'relative',
  'fixed',
  'absolute',
  'sticky',
] as const;

/**
 * The four directional properties that can be set after designating a position
 */
const directionalPositionSchema = {
  top: Type.String({title: 'Top', default: 'auto'}),
  left: Type.String({title: 'Left', default: 'auto'}),
  bottom: Type.String({title: 'Bottom', default: 'auto'}),
  right: Type.String({title: 'Right', default: 'auto'}),
};

const zIndexSchema = {
  zIndex: Type.Number({
    title: 'Stack Order',
    description:
      'The stack order of the element. Higher values are in front of lower values.',
  }),
};

const Positioning = Type.Intersect([
  Type.Object({
    position: StringEnum(positions),
  }),
  Type.Partial(Type.Object(directionalPositionSchema)),
  Type.Partial(Type.Object(zIndexSchema)),
]);

export type Positioning = Static<typeof Positioning>;
export type Position = (typeof positions)[number];

export const schema = Type.Object(
  {
    autoLayout: Type.Optional(
      Type.Boolean({
        title: 'Enable Auto Layout',
        description:
          'If checked, children will be arranged automatically based on layout settings.',
        default: false,
      })
    ),
    layout: Type.Optional(
      Type.Intersect([layoutSchema], {
        title: 'Layout',
        description: 'Controls the layout of the children',
      })
    ),
    layoutAdvanced: Type.Optional(layoutSchemaAdvanced),
    spacing: Type.Optional(spacingSchema),
    positioning: Type.Optional(
      Type.Object(
        {
          position: Type.Optional(
            StringEnum(positions, {
              title: 'Position',
              description:
                'The position of the element. Determines how it is positioned in relation to its parent. Note that positioning will be ignored in the structure view.',
              default: '',
            })
          ),
        },
        {
          dependencies: {
            position: {
              oneOf: positions.map((p) => {
                /**
                 * The directional schema only has effect if the position is not static or unset or empty.
                 * That is, when the position is static or unset or empty, the directional properties are ignored.
                 * Thus, we conditionally not render the directionalPositionSchema if the position is static or unset or empty.
                 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/top
                 */
                const effectiveDirectionalSchema =
                  p !== 'static' && p !== '' && p !== 'unset'
                    ? {...directionalPositionSchema, ...zIndexSchema}
                    : {};
                return {
                  properties: {
                    position: {
                      enum: [p],
                    },
                    ...effectiveDirectionalSchema,
                  },
                };
              }),
            },
          },
        }
      )
    ),
    background: Type.Optional(backgroundSchema),
    sizing: Type.Optional(
      Type.Pick(sizingSchema, [
        'width',
        'height',
        'minWidth',
        'maxWidth',
        'minHeight',
        'maxHeight',
      ])
    ),
    opacity: Type.Optional(opacitySchema),
  },
  {
    description:
      'If autoLayout is checked, a flex property shorthand will be passed to children. False by default',
  }
);

export const defaultFieldData: Static<typeof schema> = {
  autoLayout: false,
  layout: {
    orientation: 'vertical',
    justifyPreset: '',
    align: '',
  },
  layoutAdvanced: {
    alignSelf: '',
    flexWrap: '',
  },
  sizing: {},
  spacing: {},
  positioning: {position: ''},
  background: {
    backgroundColor: 'transparent',
    backgroundImage: '',
  },
};

export const uiSchema = {
  'ui:groups': {
    'ui:template': 'tabs',
    initialSection: 'Styling',
    sections: [
      ['Properties', []],
      [
        'Styling',
        [
          {
            'ui:template': 'accordion',
            collapsedPanels: ['Layout Advanced'],
            sections: [
              ['Layout', ['autoLayout', 'layout']],
              ['Layout Advanced', ['layoutAdvanced']],
              ['Spacing', ['spacing']],
              ['Sizing', ['sizing']],
              ['Positioning', ['positioning']],
              ['Background', ['background']],
              ['Opacity', ['opacity']],
              ['Custom Styles', ['styleAttr']],
            ],
          },
        ],
      ],
      [
        'Animations',
        [
          {
            'ui:template': 'accordion',
            sections: [['Animations', ['animationStates']]],
          },
        ],
      ],
    ],
  },
  layout: {
    orientation: {
      'ui:widget': 'LayoutWidget',
      'ui:options': {
        inline: true,
      },
    },
    justifyPreset: {
      'ui:widget': 'LayoutWidget',
      'ui:options': {
        inline: true,
        deselectable: true,
      },
    },
    align: {
      'ui:widget': 'LayoutWidget',
      'ui:options': {
        inline: true,
        deselectable: true,
      },
    },
    gap: {
      'ui:widget': 'SpacingWidget',
      'ui:options': {
        type: 'gap',
      },
    },
    'ui:order': ['orientation', 'align', 'justifyPreset', 'gap', '*'],
  },

  layoutAdvanced: {
    order: {
      'ui:widget': 'UpDownWidget',
      'ui:hideLabel': true,
      'ui:options': {
        min: 0,
      },
    },
    flexWrap: {
      'ui:widget': 'SelectWidget',
      'ui:options': {
        options: [
          {value: 'nowrap', label: 'No Wrap'},
          {value: 'wrap', label: 'Wrap'},
          {value: 'wrap-reverse', label: 'Wrap Reverse'},
        ],
        gridColumn: 'span 2', // This will make the "flexWrap" field span 2 columns using CSS grid
      },
    },
    alignSelf: {
      'ui:widget': 'select',
      'ui:options': {
        options: [
          {value: 'auto', label: 'Auto'},
          {value: 'flex-start', label: 'Flex Start'},
          {value: 'flex-end', label: 'Flex End'},
          {value: 'center', label: 'Center'},
          {value: 'baseline', label: 'Baseline'},
          {value: 'stretch', label: 'Stretch'},
        ],
        gridColumn: 'span 2', // This will make the "alignSelf" field span 2 columns using CSS grid
      },
    },
    flexGrow: {
      'ui:widget': 'UpDownWidget',
      'ui:hideLabel': true,
      'ui:options': {
        min: 0,
      },
    },
    flexShrink: {
      'ui:widget': 'UpDownWidget',
      'ui:hideLabel': true,
      'ui:options': {
        min: 0,
      },
    },
    flexBasis: {
      'ui:widget': 'SizingWidget',
    },
    'ui:options': {
      columns: 2,
    },
  },

  spacing: {
    margin: {
      'ui:widget': 'SpacingWidget',
      'ui:options': {
        type: 'margin',
      },
    },
    padding: {
      'ui:widget': 'SpacingWidget',
      'ui:options': {
        type: 'padding',
      },
    },
  },
  sizing: {
    'ui:options': {
      columns: 2,
    },
    width: {
      'ui:widget': 'SizingWidget',
    },
    height: {
      'ui:widget': 'SizingWidget',
    },
    minWidth: {
      'ui:widget': 'SizingWidget',
    },
    maxWidth: {
      'ui:widget': 'SizingWidget',
    },
    minHeight: {
      'ui:widget': 'SizingWidget',
    },
    maxHeight: {
      'ui:widget': 'SizingWidget',
    },
  },
  positioning: {
    'ui:order': ['position', 'top', 'left', 'bottom', 'right', '*'],
    position: {
      'ui:widget': 'select',
      'ui:options': {
        gridColumn: 'span 2', // This will make the "position" field span 2 columns using CSS grid
      },
    },
    top: {
      'ui:widget': 'SizingWidget',
    },
    left: {
      'ui:widget': 'SizingWidget',
    },
    bottom: {
      'ui:widget': 'SizingWidget',
    },
    right: {
      'ui:widget': 'SizingWidget',
    },
    zIndex: {
      'ui:widget': 'UpDownWidget',
      'ui:options': {
        gridColumn: 'span 2', // This will make the "zIndex" field span 2 columns using CSS grid
      },
    },
    'ui:options': {
      columns: 2, // Applies 2-column grid to the object, but individual fields can override this with gridColumn
    },
  },
  background: {
    backgroundColor: {
      'ui:widget': 'color',
    },
    backgroundImage: {
      'ui:widget': 'AssetSelectWidget',
      'ui:options': {
        buttonTitle: 'Select Asset',
      },
    },
    backgroundAttachment: {
      'ui:widget': 'select',
      'ui:options': {
        condition: {
          field: 'backgroundImage',
          isNotEmpty: true, // Show when backgroundImage is not empty
        },
      },
    },
    backgroundRepeat: {
      'ui:widget': 'select',
      'ui:options': {
        condition: {
          field: 'backgroundImage',
          isNotEmpty: true,
        },
      },
    },
    backgroundPosition: {
      'ui:widget': 'select',
      'ui:options': {
        condition: {
          field: 'backgroundImage',
          isNotEmpty: true,
        },
      },
    },
    backgroundSize: {
      'ui:widget': 'select',
      'ui:options': {
        condition: {
          field: 'backgroundImage',
          isNotEmpty: true,
        },
      },
    },
    'ui:order': [
      'backgroundColor',
      'backgroundImage',
      'backgroundAttachment',
      'backgroundRepeat',
      'backgroundPosition',
      'backgroundSize',
    ],
  },
  opacity: {
    opacity: {
      'ui:widget': 'range',
      'ui:options': {
        type: 'percentage',
      },
    },
  },
};

const instructions = SingleUnion(
  SubscribesTo({
    topic: `${reactName}:scrollTo`,
    description: scrollToDescription,
    meta: {
      scrollX: Type.Optional(Type.String()),
      scrollY: Type.Optional(Type.String()),
      anchorElId: Type.Optional(Type.String()),
      elementId: Type.Optional(Type.String()),
    },
  })
);

export const ComponentDefinition = createComponentAdmin({
  id: '57accedd-28cd-4c27-9d27-2b185af3a88c',
  reactName,
  name: 'Stacked Layout',
  slug: reactName,
  description: 'Stacked layout',
  version: 1,
  defaultFieldData,
  slotConfiguration: {
    items: {maxModules: 100, displayWidth: 12, title: 'Modules'},
  },
  schema,
  uiSchema,
  instructions,
  category,
})
  .withAnimationStates()
  .withStyles()
  .build();

export type SchemaType = SchemaTypeHelper<typeof ComponentDefinition>;
