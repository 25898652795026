import {
  backgroundSchema,
  backgroundConditionalSchema,
  ComponentConfig,
  sizingSchema,
  layoutSchemaAdvanced,
  spacingSchema,
  layoutSchema,
} from '@backstage-components/base';
import {CSSProperties} from 'react';
import {Positioning} from './StackLayoutDefinition';
import {Static} from '@sinclair/typebox';

type Layout = Static<typeof layoutSchema>;
// Define the LayoutAdvanced interface based on the expected advanced flexbox properties
type LayoutAdvanced = Static<typeof layoutSchemaAdvanced>;
type BackgroundConditional = Static<typeof backgroundConditionalSchema>;
// Define types for background and sizing objects
type Background = Static<typeof backgroundSchema> & BackgroundConditional;
type Sizing = Static<typeof sizingSchema>;
type Spacing = Static<typeof spacingSchema>;

// Type Guard to check if the orientation is valid for flexDirection
const isFlexDirection = (
  value: string | undefined
): value is CSSProperties['flexDirection'] => {
  return ['row', 'row-reverse', 'column', 'column-reverse'].includes(
    value ?? ''
  );
};

const defaults = () => {
  const {
    properties: {backgroundColor, backgroundImage},
  } = backgroundSchema;
  const {
    properties: {
      backgroundAttachment,
      backgroundPosition,
      backgroundRepeat,
      backgroundSize,
    },
  } = backgroundConditionalSchema;
  return {
    background: {
      // only bother adding background color if it is not transparent
      backgroundColor: backgroundColor?.default || 'transparent',
      backgroundImage: backgroundImage?.default || 'none',
      ...(backgroundSchema.dependencies?.backgroundImage && {
        backgroundAttachment: backgroundAttachment.default || 'scroll',
        backgroundRepeat: backgroundRepeat.default || 'no-repeat',
        backgroundPosition: backgroundPosition.default || 'center center',
        backgroundSize: backgroundSize.default || 'cover',
      }),
    },
    sizing: {},
    layoutAdvanced: {},
  };
};

// Compute layout based on flex properties
export const computeLayout = (props: Layout): CSSProperties | undefined => {
  const {orientation, align, gap, justifyPreset} = props;
  let direction: string;

  switch (orientation) {
    case 'horizontal':
      direction = 'row';
      break;
    case 'horizontal-reverse':
      direction = 'row-reverse';
      break;
    case 'vertical-reverse':
      direction = 'column-reverse';
      break;
    case 'vertical':
    default:
      direction = 'column';
      break;
  }

  if (!isFlexDirection(direction)) {
    throw new Error(`Invalid flex direction: ${direction}`);
  }

  return {
    display: 'flex',
    flexDirection: direction,
    justifyContent:
      justifyPreset === 'start'
        ? 'flex-start'
        : justifyPreset === 'end'
          ? 'flex-end'
          : justifyPreset,
    alignItems: align,
    // Only add gap if it is provided and is not 0
    ...(gap && gap !== '0px 0px' && {gap}),
  };
};

// Compute background styles
export const computeBackgroundStyles = (
  background: Background
): CSSProperties => ({
  // Only add backgroundColor if it is not transparent
  ...(background.backgroundColor &&
    background.backgroundColor !== defaults().background.backgroundColor && {
      backgroundColor: background.backgroundColor,
    }),
  // Only apply backgroundImage styles if backgroundImage is provided
  ...(background.backgroundImage && {
    backgroundImage: `url(${background.backgroundImage})`,
    backgroundAttachment:
      background.backgroundAttachment ||
      defaults().background.backgroundAttachment,
    backgroundRepeat:
      background.backgroundRepeat || defaults().background.backgroundRepeat,
    backgroundPosition:
      background.backgroundPosition || defaults().background.backgroundPosition,
    backgroundSize:
      background.backgroundSize || defaults().background.backgroundSize,
  }),
});

// Compute sizing styles and explicitly type return as CSSProperties
export const computeSizingStyles = (
  sizing: Sizing,
  scope: ComponentConfig['scope']
): CSSProperties => {
  const {height, maxHeight, maxWidth, minHeight, minWidth, width} = sizing;
  const heights = {height, minHeight, maxHeight};
  const widths = {width, minWidth, maxWidth};
  if (scope === 'admin') {
    return heights;
  } else {
    return {...heights, ...widths};
  }
};

// Compute position styles
export const computePositionStyles = (
  positioning: Partial<Positioning>
): CSSProperties => {
  const {
    position,
    top = 'auto',
    right = 'auto',
    bottom = 'auto',
    left = 'auto',
    zIndex,
  } = positioning;
  return {
    position: position === '' ? undefined : position,
    top,
    right,
    bottom,
    left,
    zIndex,
  };
};

export const computeOpacityStyles = (opacity: unknown) => {
  const opacityValue = typeof opacity === 'number' ? opacity : 1;
  return {
    // Only apply opacity styles if opacity is provided or if it is less than 1
    // Ensure opacity is between 0 and 1
    ...(opacityValue !== 1 && {
      opacity: Math.min(Math.max(opacityValue, 0), 1),
    }),
  };
};

export const computeAdvancedLayoutStyles = (
  layoutAdvanced: LayoutAdvanced
): CSSProperties => {
  const {alignSelf, order, flexWrap, flexGrow, flexShrink, flexBasis} =
    layoutAdvanced;
  return {
    alignSelf: alignSelf === '' ? undefined : alignSelf,
    order,
    flexWrap: flexWrap === '' ? undefined : flexWrap,
    flexGrow,
    flexShrink,
    flexBasis,
  };
};

export const computeSpacingStyles = (spacing: Spacing): CSSProperties => ({
  margin: spacing?.margin,
  padding: spacing?.padding,
});
