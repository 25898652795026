import {
  LayoutModule,
  Render,
  scrollTo,
  useAnimationStates,
  useRenderedChildren,
  useShowInstructions,
} from '@backstage-components/base';
import {css, cx} from '@emotion/css';
import {motion} from 'framer-motion';
import {useSubscription} from 'observable-hooks';
import {FC, Fragment, PropsWithChildren, useCallback} from 'react';
import {
  ComponentDefinition,
  Positioning,
  SchemaType,
  reactName,
} from './StackLayoutDefinition';
import {
  computeLayout,
  computeBackgroundStyles,
  computeSizingStyles,
  computeAdvancedLayoutStyles,
  computeOpacityStyles,
  computePositionStyles,
  computeSpacingStyles,
} from './styledUtils';

// we extend the schema type because rjsf will not automatically pick up dependency types
export interface StackLayoutProps extends SchemaType {
  positioning?: Positioning;
}

export type StackLayoutDefinition = LayoutModule<
  typeof reactName,
  StackLayoutProps
>;

export const StackLayout: FC<StackLayoutDefinition> = (definition) => {
  const {slotRenderer: Component = () => <Fragment />, props} = definition;
  const {autoLayout} = props;
  const renderFn: Render = useCallback(
    (Component, details, members) => {
      const itemsLength = 100 / members.length;
      const flexShorthand =
        autoLayout === true ? `flex: 1 1 ${itemsLength}%;` : '';
      const componentStyle = `${details.style ? `${details.style}; ` : ''}${flexShorthand}`;
      return (
        <Component
          key={`${details.path.join(':')}:${details.mid}`}
          {...details}
          style={componentStyle}
        />
      );
    },
    [autoLayout]
  );
  const renderedChildren = useRenderedChildren(
    Component,
    definition.slots,
    renderFn
  );

  return <StackLayoutBase {...definition}>{renderedChildren}</StackLayoutBase>;
};

export const StackLayoutBase: FC<PropsWithChildren<StackLayoutDefinition>> = (
  definition
) => {
  const {props, children, config} = definition;

  const {
    layout,
    layoutAdvanced,
    background,
    opacity,
    positioning,
    sizing,
    spacing,
    animationStates,
  } = props;

  const {justifyPreset, align, orientation, gap} = layout || {
    orientation: 'vertical',
  };
  const layoutStyle = computeLayout({justifyPreset, align, orientation, gap});
  const backgroundStyles = computeBackgroundStyles(background || {});
  const sizingStyles = computeSizingStyles(sizing || {}, config.scope);
  const spacingStyles = computeSpacingStyles(spacing || {});
  const advancedLayoutStyles = computeAdvancedLayoutStyles(
    layoutAdvanced || {}
  );
  const opacityStyle = computeOpacityStyles(opacity?.opacity);
  const positionStyle = computePositionStyles(positioning || {});

  const {observable, broadcast} = useShowInstructions(
    ComponentDefinition.instructions,
    definition
  );
  const motionOptions = useAnimationStates(
    observable,
    broadcast,
    animationStates
  );

  useSubscription(observable, (inst) => {
    if (inst.type === 'Stacked:scrollTo') {
      const {elementId, anchorElId, scrollX, scrollY} = inst.meta;
      scrollTo({elementId, anchorElId, scrollX, scrollY});
    }
  });

  const styleClassName = css`
    ${definition.style}
    ${props.styleAttr}
  `;

  return (
    <motion.div
      id={definition.id}
      data-testid={reactName}
      className={cx(
        css({
          ...layoutStyle,
          ...advancedLayoutStyles,
          ...backgroundStyles,
          ...sizingStyles,
          ...positionStyle,
          ...opacityStyle,
          ...spacingStyles,
        }),
        styleClassName,
        definition.mid
      )}
      {...motionOptions}
    >
      {children}
    </motion.div>
  );
};
