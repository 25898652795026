/// <reference types="@emotion/react/types/css-prop" />
import {ModuleNode, WithHtmlId} from '@backstage-components/base';
import {FC, PropsWithChildren} from 'react';
import {SchemaType} from './StackLayoutDefinition';
import {
  computeLayout,
  computeBackgroundStyles,
  computeSizingStyles,
  computeOpacityStyles,
  computeAdvancedLayoutStyles,
} from './styledUtils';
import {css, cx} from '@emotion/css';

export type StackedProps = SchemaType;

export const StackLayoutPreviewBase: FC<
  PropsWithChildren<ModuleNode<'Stacked', StackedProps> & WithHtmlId>
> = ({children, module: {props, id, config}}) => {
  const {layout, background, sizing, opacity, layoutAdvanced} = props;

  // Compute the necessary styles using the utility functions
  const {justifyPreset, align, orientation, gap} = layout || {
    orientation: 'vertical',
  };
  const layoutStyle = computeLayout({justifyPreset, align, orientation, gap});
  const advancedLayoutStyles = computeAdvancedLayoutStyles(
    layoutAdvanced || {}
  );
  const backgroundStyles = computeBackgroundStyles(background || {});
  const sizingStyles = computeSizingStyles(sizing || {}, config.scope);
  const opacityStyles = computeOpacityStyles(opacity?.opacity);

  const styleClassName = css`
    ${props.styleAttr}
  `;

  return (
    <div
      id={id}
      className={cx(
        css({
          ...layoutStyle,
          ...advancedLayoutStyles,
          ...backgroundStyles,
          ...sizingStyles,
          ...opacityStyles,
        }),
        styleClassName // If any additional inline styles are passed through styleAttr
      )}
    >
      {children}
    </div>
  );
};

export const StackLayoutPreview: FC<
  ModuleNode<'Stacked', StackedProps> & WithHtmlId
> = (props) => {
  const moduleProps = props.module.props;
  const Slot = props.slotComponent;
  return (
    <StackLayoutPreviewBase {...props}>
      <Slot
        slotName="items"
        orientation={moduleProps.layout?.orientation}
        autoLayout={moduleProps.autoLayout}
      />
    </StackLayoutPreviewBase>
  );
};
